// Layout file

// All Pages conform to this same layout

<template>
  <q-layout view="lHh Lpr lFf" class="background">
    <q-page-container class="contain-app">

      <!-- In-app router that switches between pages on the client side -->

      <router-view />
      
    </q-page-container>
    <footer id="footer">
        <div class="container">
          <div class="row">
            <div class="col-9" style="margin: auto !important; padding-top: 25px !important; font-size: 18px">
              <p class=" text-center" style>
                &copy; {{ year }}
                <a href="https://azbf.org/" target="blank">Arizona Bar Foundation</a> | All Rights Reserved
              </p>


              <p class="text-center text-caption producer-text">
                This website was produced by the Arizona Bar Foundation under Cooperative Agreement 2018-V3-GX-K010, awarded by the Office for Victims of Crime, Office of Justice Programs, U.S. Department of Justice. 
                The opinions, findings, and conclusions or recommendations expressed in this website are those of the contributors and do not necessarily represent the official position or policies of the U.S. Department of Justice.
              </p>


              <p class="text-center text-caption copyright-text">
                This website has been prepared for general information purposes only.
                The information on this website is not legal advice. Legal advice is dependent upon the specific circumstances of each situation.
                Also, the law may vary from state-to-state or county-to-county, so that some information in this website may not be correct for your situation.
                Finally, the information contained on this website is not guaranteed to be up to date.
                Therefore, the information contained in this website cannot replace the advice of competent legal counsel licensed in your jurisdiction.
 </p>
            </div>
          </div>
        </div>
      </footer>
  </q-layout>
</template>

<script>

const BASE_URL = process.env.VUE_APP_BASE_URL;

export default {
  name: "MyLayout",
  data() {
    return {};
  },
  computed: {
    year: function () {
      return new Date().getFullYear();
    },
  },
  mounted() {
    },
  methods: {
    exit() {
      window.location = "http://www.google.com";
    },
    restart() {
      this.$router.push("/");
      location.reload();
    },
  },
};
</script>


<style>

.background {
  background-attachment: scroll;
  background-image: linear-gradient(
    to bottom,
    #fff 50%,
    #82b8c4 50%
  );
  height: 100%;
} 

.foundationFont {
  font-family: "Open Sans", sans-serif;
}

.contain-app {
  max-width: 1920px;
  margin: auto;
}

#footer {
  max-width: 1920px;
  margin: auto;
}

.producer-text {
  font-size: 14px;;
  font-family: "Open Sans", sans-serif;
}

.copyright-text {
  font-size: 10px;;
    font-family: "Open Sans", sans-serif;

}

</style>


