var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-layout",
    { staticClass: "background", attrs: { view: "lHh Lpr lFf" } },
    [
      _c(
        "q-page-container",
        { staticClass: "contain-app" },
        [_c("router-view")],
        1
      ),
      _c("footer", { attrs: { id: "footer" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-9",
                staticStyle: {
                  margin: "auto !important",
                  "padding-top": "25px !important",
                  "font-size": "18px",
                },
              },
              [
                _c("p", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n               © " +
                      _vm._s(_vm.year) +
                      "\n               "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "https://azbf.org/", target: "blank" } },
                    [_vm._v("Arizona Bar Foundation")]
                  ),
                  _vm._v(" | All Rights Reserved\n             "),
                ]),
                _c(
                  "p",
                  { staticClass: "text-center text-caption producer-text" },
                  [
                    _vm._v(
                      "\n               This website was produced by the Arizona Bar Foundation under Cooperative Agreement 2018-V3-GX-K010, awarded by the Office for Victims of Crime, Office of Justice Programs, U.S. Department of Justice. \n               The opinions, findings, and conclusions or recommendations expressed in this website are those of the contributors and do not necessarily represent the official position or policies of the U.S. Department of Justice.\n             "
                    ),
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "text-center text-caption copyright-text" },
                  [
                    _vm._v(
                      "\n               This website has been prepared for general information purposes only.\n               The information on this website is not legal advice. Legal advice is dependent upon the specific circumstances of each situation.\n               Also, the law may vary from state-to-state or county-to-county, so that some information in this website may not be correct for your situation.\n               Finally, the information contained on this website is not guaranteed to be up to date.\n               Therefore, the information contained in this website cannot replace the advice of competent legal counsel licensed in your jurisdiction.\n"
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }